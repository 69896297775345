import React from "react";
import clove_logo from "../../assets/clove_logo.svg";

export default function Header() {
  return (
    <header className="header flex flex-row justify-center sm:justify-between items-center fixed top-0 left-0 w-full px-8 py-4">
      <div className="header-left">
        {/* replace this with Taskl.ie logo once we have it */}
        {/* <img src={clove_logo} className="h-12" /> */}
        <h1 className="nunito-semibold text-white text-3xl">Taskl.ie</h1>
      </div>
      <div className="header-right opacity-50 text-white sm:block hidden">
        A project of clove.ie
      </div>
    </header>
  );
}
