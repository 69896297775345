import DecorationsLeft from "../components/backgrounds/DecorationsLeft";
import DecorationsRight from "../components/backgrounds/DecorationsRight";
import React, { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { BeatLoader } from "react-spinners";
import Header from "../components/layout/Header";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const h3Ref = useRef();
  const h1Ref = useRef();
  const h2Ref = useRef();
  const inputRef = useRef();

  useLayoutEffect(() => {
    gsap.from(h3Ref.current, {
      scale: "0.3",
      opacity: 0,
      delay: 0.3,
    });
    gsap.to(h3Ref.current, {
      scale: "1",
      opacity: 0.8,
      delay: 0.3,
    });
    gsap.from(h1Ref.current, {
      scale: "0.3",
      opacity: 0,
      delay: 0.5,
    });
    gsap.to(h1Ref.current, {
      scale: "1",
      opacity: 0.9,
      delay: 0.5,
    });
    gsap.from(h2Ref.current, {
      scale: "0.3",
      opacity: 0,
      delay: 0.7,
    });
    gsap.to(h2Ref.current, {
      scale: "1",
      opacity: 0.6,
      delay: 0.7,
    });
    gsap.from(inputRef.current, {
      scale: "0.3",
      opacity: 0,
      delay: 0.9,
    });
    gsap.to(inputRef.current, {
      scale: "1",
      opacity: 1,
      delay: 0.9,
    });
  });

  const handleSend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // send email
    setIsLoading(false);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Taskl.ie - Best todo list app with AI</title>
        <meta
          name="description"
          content="An AI-assisted task management system, to reduce stress and improve the quality of flow time."
        />
        <link rel="canonical" href="https://taskl.ie" />
      </Helmet>
      <DecorationsLeft className="svg-background background-left absolute left-0 bottom-0 select-none pointer-events-none" />
      <DecorationsRight className="svg-background background-right absolute -right-2 bottom-0 select-none pointer-events-none" />
      <Header />
      <main className="content w-full flex items-center min-h-screen">
        <div className="items-center justify-center w-full flex-col flex">
          <div className="content-container box-border w-full">
            <h3
              className="nunito text-3xl text-center text-neutral-200 w-full"
              style={{ opacity: 0 }}
              ref={h3Ref}
            >
              Simplify task management with an AI-assisted system.
            </h3>
            <h1
              className="nunito-semibold text-7xl font-bold text-center text-white w-full my-7"
              style={{ opacity: 0 }}
              ref={h1Ref}
            >
              More than a todo app.
            </h1>
            <h2
              className="nunito text-xl text-center text-neutral-400 w-full"
              style={{ opacity: 0 }}
              ref={h2Ref}
            >
              Intelligent categorization and prioritization suggestions reduce
              stress. Seamlessly integrates with your calendar and productivity
              tools for enhanced workflow.
            </h2>
            <form
              onSubmit={handleSend}
              className="flex flex-row items-center justify-center mt-10 px-5 mb-5 w-full"
              ref={inputRef}
            >
              <input
                placeholder="Your email"
                type="email"
                className="border-neutral-400 flex-1 border-2 border-solid bg-transparent px-5 py-3 md:mr-3 focus:border-neutral-400 text-neutral-400 box-border w-64"
              />
              <button
                type="submit"
                className="bg-white border-white border-2 px-10 py-3 cursor-pointer hover:opacity-80 transition-opacity duration-100 md:mt-0 mt-5 active:opacity-90 w-64"
              >
                {!isLoading ? (
                  "Join waiting list"
                ) : (
                  <BeatLoader color="#232825" size={10} />
                )}
              </button>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
